import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../service/webapi.service';
import { BufferInfo } from './buffer-info';

@Component({
  selector: 'app-buffer-info',
  templateUrl: './buffer-info.component.html',
  styleUrls: ['./buffer-info.component.css']
})
export class BufferInfoComponent implements OnInit {
  bufferInfo: BufferInfo[];
  msg: string;
  errorVisible: boolean = false;
  sortColumn: string;
  sortAsc: boolean;
  constructor(private _webapiService: WebapiService) { }

  ngOnInit() {
    this.GetBufferInfo();
  }
  GetBufferInfo() {
    this._webapiService.get('api/Navitrans/BufferInfo')
      .subscribe(info => {
        this.bufferInfo = info;
      },
        error => {
          this.ErrorHandling(error);
        });
  }
  ErrorHandling(error: any) {
    this.msg = <any>error;
    console.error(error);
    this.errorVisible = true;
  }
  compare(a: number | String, b: number | String, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  Sort(header: string) {
    if (header == this.sortColumn) { this.sortAsc = !this.sortAsc; }
    else { this.sortAsc = true; this.sortColumn = header; }
    switch (header) {
      case "OrderType":
        this.bufferInfo.sort((a, b) => {
          return this.compare(a.orderType, b.orderType, this.sortAsc)
        })
        break;
      case "CustomerCode":
        this.bufferInfo.sort((a, b) => {
          return this.compare(a.customerCode, b.customerCode, this.sortAsc)
        })
        break;
      case "CustomerName":
        this.bufferInfo.sort((a, b) => {
          return this.compare(a.customerName, b.customerName, this.sortAsc)
        })
        break;
      case "Reference":
        this.bufferInfo.sort((a, b) => {
          return this.compare(a.reference, b.reference, this.sortAsc)
        })
        break;
      case "BufferError":
        this.bufferInfo.sort((a, b) => {
          return this.compare(a.bufferError, b.bufferError, this.sortAsc)
        })
        break;
      default:
        break;
    }
  }
}
