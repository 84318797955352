import { PlanningCustomer } from '../planning/planning';
export class OrtecFileParameters {
    constructor(
        public date: Date,
        public customerCode: string,
        public orders: boolean
    ) { }
}

export class OrtecNavitransCustomer extends PlanningCustomer  {
    constructor(
        public date: Date,
        public clientNumber: string,
        public name: string,
        public searchName: string
    ) {
        super(date, clientNumber, name, searchName);
    }
}

export class OrtecFileLines {
  constructor(
    public date: Date,
    public orders: boolean,
    public ortecLines: OrtecGridInfo[]
  ) { }

  amountLines() {
    return this.ortecLines.length;
  }
}

export class CustomOrderAmount {
  constructor(
    public customer: string,
    public amount: number,
    public selected : number
  ) { }
}

export class OrtecGridInfo {
  constructor(
    public selected: Boolean,
    public ortecLine: OrtecFileModel
  ) { }
}

export class OrtecFileModel {
  constructor(
    public navitransNumber: String,
    public reference: String,
    public orderNumber: number,
    public customerName: String,
    public clientNumber: String,
    public clientname: String,
    public street: String,
    public houseNumber: String,
    public postalcode: String,
    public city: String,
    public country: String,
    public xcoordinateGPS: number,
    public ycoordinateGPS: number,
    public clienttimewindow1start: String,
    public clienttimewindow1end: String,
    public clienttimewindow2start: String,
    public clienttimewindow2end: String,
    public fixedunloadingtime: number,
    public variableunloadingtimeperKG: number,
    public variableunloadingtimeperPallet: number,
    public quantitytodeliverinKG: number,
    public quantitytodeliverinPallets: number,
    public quantitytopickupinKG: number,
    public quantitytopickupinPallets: number,
    public commentsExtrainfo: String,
    public requiredvehicletype1: String,
    public requiredvehicletype2: String,
    public requiredEquipmentCompetence1: String,
    public helperneeded: number,
    public dEPOT1: String
  ) { }
}

