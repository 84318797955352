
export class PlanningCustomer {
  constructor(
    public date: Date,
    public clientNumber: string,
    public name: string,
    public searchName: string
  ) { }
}

export class PlanningDepot {
  constructor(
    public depot: string,
    public code: string
  ) { }
}

export class CustomOrderAmount {
  constructor(
    public customer: string,
    public amount: number,
    public selected: number
  ) { }
}

export class PlanningGridInfo {
  constructor(
    public selected: Boolean,
    public planningLine: PlanningFileModel
  ) { }
}

export class PlanningFileModel {
  constructor(
    public selected: Boolean,
    public navitransNumber: String,
    public reference: String,
    public customerName: String,
    public clientNumber: String,
    public clientname: String,
    public street: String,
    public postalcode: String,
    public city: String,
    public country: String,
    public clienttimewindow1start: String,
    public clienttimewindow1end: String,
    public clienttimewindow2start: String,
    public clienttimewindow2end: String,
    public depoT1: String,
    public allreadyExported: boolean,
    public phone: string,
    public comment: string
  ) { }
}
