import { Injectable } from '@angular/core';
//import { Response, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class WebapiService {
    urlStart: string = "/"
    //urlStart: string = "/NavitransExtra/"
    constructor(private _http: HttpClient, private _location: Location) { }

    get(url: string): Observable<any> {
      return this._http.get(this._location.prepareExternalUrl(url));
        //    .pipe(
        //catchError(this.handleError('get: ' + this._location.prepareExternalUrl(url), []))
        //);
    }

    getId(url: string, id: number): Observable<any> {
      return this._http.get(this._location.prepareExternalUrl(url));
        //.pipe(
        //catchError(this.handleError('getId: ' + this._location.prepareExternalUrl(url), []))
        //);
    }

    post(url: string, model: any): Observable<any> {
      return this._http.post(this._location.prepareExternalUrl(url), model, httpOptions);
        //.pipe(
        //catchError(this.handleError('post: ' + this._location.prepareExternalUrl(url), model))
        //);
    }

    put(url: string, id: number, model: any): Observable<any> {
      return this._http.put(this._location.prepareExternalUrl(url) + id, model, httpOptions);
        //.pipe(
        //catchError(this.handleError('put: ' + this._location.prepareExternalUrl(url) + id, model))
        //);
    }

  delete(url: string, id: number): Observable<any> {
    return this._http.delete(this._location.prepareExternalUrl(url) + id, httpOptions);
      //.pipe(
      //catchError(this.handleError('delete: ' + this._location.prepareExternalUrl(url) + id, []))
      //);
  }

  postFile(url: string, formToUpload: any): Observable<any> {
    return this._http.post(this._location.prepareExternalUrl(url), formToUpload, { observe: 'response', responseType: 'blob' });
      //.pipe(
      //catchError(this.handleError('postFile: ' + this._location.prepareExternalUrl(url), []))
      //);
  }

  //  private handleError(error: Response) {
  //      console.error(error);
  //      //return Observable.throw(error.json().error || 'Server error');
  //      return Observable.throw(error || 'Server error');
  //}
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      //return of(result as T);
      return error;
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    //this.messageService.add('HeroService: ${message}');
  }
}
