﻿import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core';
//import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.css'],
    //animations: [
    //    trigger('loading', [
    //        transition('void => *', [
    //            style({ transform: 'scale3d(.3, .3, .3)' }),
    //            animate(100)
    //        ]),
    //        transition('* => void', [
    //            animate(100, style({ transform: 'scale3d(.0, .0, .0)' }))
    //        ])
    //    ])
    //]
})
export class LoadingComponent implements OnInit {
    @Input() visible: boolean;

    constructor() { }

    ngOnInit() { }
}