import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { PlanningCustomer, CustomOrderAmount, PlanningFileModel, PlanningDepot } from './planning'
//import { OuterSubscriber } from 'rxjs/internal/OuterSubscriber';

@Component({
    selector: 'app-planning',
    templateUrl: './planning.component.html',
    styleUrls: ['./planning.component.css']
})
/** Planning component*/
export class PlanningComponent implements OnChanges {
  @Input() planningLines: PlanningFileModel[];
  @Input() customers: PlanningCustomer[];
  //@Input() customerOrderAmount: CustomOrderAmount[];
  @Input() cbOrders: boolean;
  @Input() cbVisibleIncludeExported: boolean;
  @Input() cbIncludeExported: boolean;
  @Input() depots: PlanningDepot[];
  @Output() changeDate: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() changeCbOrders = new EventEmitter();
  @Output() getPlanningData: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() makeFile = new EventEmitter();
  @Output() resetPlanningData = new EventEmitter();
  @Output() changeSelected: EventEmitter<String> = new EventEmitter<String>();
  @Output() changeCustomer: EventEmitter<string> = new EventEmitter<string>();
  //@Output() sort: EventEmitter<string> = new EventEmitter<string>();
  @Output() changeCbIncludeExported = new EventEmitter();
  @Output() changeDepot: EventEmitter<string> = new EventEmitter<string>();
  filter: string;
  planDate: number = Date.now();
  selected: number;
  sortColumn: string = "";
  sortAsc: boolean = false;
  customerOrderAmount: CustomOrderAmount[];
  customer: string;
  planningLinesInfo: PlanningFileModel[];
  filterDate: Date;
  depot: string;
  
    /** Planning ctor */
  constructor() {
    this.filterDate = new Date();
  }

  ChangeDate(date: HTMLInputElement) { this.filterDate = date.valueAsDate; this.changeDate.emit(date.valueAsDate); }

  ChangeCbOrders() { this.changeCbOrders.emit(); }

  ChangeCbIncludeExported() { this.changeCbIncludeExported.emit(); }

  GetPlanningData(date: HTMLInputElement) { this.getPlanningData.emit(date.valueAsDate); }

  MakeFile() { this.makeFile.emit(); }

  ResetPlanningData() { this.resetPlanningData.emit(); }

  ChangeSelected(item: PlanningFileModel) {
    this.changeSelected.emit(item.navitransNumber);
    //this.planningLines.filter(p => p.navitransNumber === item.navitransNumber).forEach(f => f.selected = !f.selected);
    this.BuildCustomerOrderAmount();
  }

  ChangeCustomer() { this.changeCustomer.emit(this.customer); }

  ChangeDepot() { this.changeDepot.emit(this.depot); }
  //Sort(header: string) { this.sort.emit(header); }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if (changes.planningLines) {
      this.planningLinesInfo = changes.planningLines.currentValue;
      this.BuildCustomerOrderAmount();
    }
  }

  SetFilter(customer: string) {
    if (this.filter == customer) {
      this.filter = '';
    } else {
      this.filter = customer;
    }
  }

  BuildCustomerOrderAmount() {
    if (this.planningLinesInfo) {
      this.selected = this.planningLinesInfo.filter(item => item.selected).length;
      var discCustomers = Array.from(new Set(this.planningLinesInfo.map(item => item.clientname)));
      if (this.customerOrderAmount && this.customerOrderAmount.length > 0) {
        this.customerOrderAmount = this.customerOrderAmount.filter(item => item.amount < 0);
      }
      for (var i = 0; i < discCustomers.length; i++) {
        if (this.customerOrderAmount && this.customerOrderAmount.length > 0) {
          this.customerOrderAmount.push(new CustomOrderAmount(discCustomers[i].toString(), this.planningLinesInfo.filter(item => item.clientname === discCustomers[i]).length, this.planningLinesInfo.filter(item => item.selected && item.clientname === discCustomers[i]).length));
        } else {
          this.customerOrderAmount = [new CustomOrderAmount(discCustomers[i].toString(), this.planningLinesInfo.filter(item => item.clientname === discCustomers[i]).length, this.planningLinesInfo.filter(item => item.selected && item.clientname === discCustomers[i]).length)];
        }
      }
    }
  }

  compare(a: number | String, b: number | String, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  Sort(header: string) {
    if (header == this.sortColumn) { this.sortAsc = !this.sortAsc; }
    else { this.sortAsc = true; this.sortColumn = header; }
    switch (header) {
      case "Navitrans":
        this.planningLinesInfo.sort((a, b) => {
          return this.compare(a.navitransNumber, b.navitransNumber, this.sortAsc)
        })
        break;
      case "client":
        this.planningLinesInfo.sort((a, b) => {
          return this.compare(a.clientname, b.clientname, this.sortAsc)
        })
        break;
      case "reference":
        this.planningLinesInfo.sort((a, b) => {
          return this.compare(a.reference, b.reference, this.sortAsc)
        })
        break;
      case "customername":
        this.planningLines.sort((a, b) => {
          return this.compare(a.customerName, b.customerName, this.sortAsc)
        })
        break;
      case "street":
        this.planningLinesInfo.sort((a, b) => {
          return this.compare(a.street, b.street, this.sortAsc)
        })
        break;
      case "postalcode":
        this.planningLinesInfo.sort((a, b) => {
          return this.compare(a.country + ' ' + a.postalcode, b.country + ' ' + b.postalcode, this.sortAsc)
        })
        break;
      case "city":
        this.planningLinesInfo.sort((a, b) => {
          return this.compare(a.city, b.city, this.sortAsc)
        })
        break;
      case "timewindow":
        this.planningLinesInfo.sort((a, b) => {
          return this.compare(a.clienttimewindow1start + '-' + a.clienttimewindow1end, b.clienttimewindow1start + '-' + b.clienttimewindow1end, this.sortAsc)
        })
        break;
      case "Hub":
        this.planningLinesInfo.sort((a, b) => {
          return this.compare(a.depoT1, b.depoT1, this.sortAsc)
        })
        break;
      case "phone":
        this.planningLinesInfo.sort((a, b) => {
          return this.compare(a.phone, b.phone, this.sortAsc)
        })
        break;
      case "comment":
        this.planningLinesInfo.sort((a, b) => {
          return this.compare(a.comment, b.comment, this.sortAsc)
        })
        break;
      default:
        break;
    }
  }
}
