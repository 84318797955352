import { Component } from '@angular/core';
import { WebapiService } from '../service/webapi.service';
import { PoltronesofaSearch, PoltronesofaInfo, PoltronesofaRetourInfo, PoltronesofaStorageInfo } from './Poltronesofa'

@Component({
    selector: 'app-poltronesofa',
    templateUrl: './poltronesofa.component.html',
    styleUrls: ['./poltronesofa.component.css']
})
/** Poltronesofa component*/
export class PoltronesofaComponent {
  /** Poltronesofa ctor */
  indLoading: boolean = false;
  cbOrders: boolean;
  sortAsc: boolean = true;
  sortColumn: string = '';
  sKlant: string;
  sOrder: string;
  msg: string;
  searchValues: PoltronesofaSearch = new PoltronesofaSearch('','',false);
  searchInfo: PoltronesofaInfo[];
  retourInfoNeeded: boolean = false;
  storageInfoNeeded: boolean = false;
  retourInfo: PoltronesofaRetourInfo;
  storageInfo: PoltronesofaStorageInfo;
  constructor(
    private _webapiService: WebapiService) {

  }

  ChangeCbOrders() {
    //this.cbOrders = !this.cbOrders;
  }

  Search() {
    this.searchValues.completed = this.cbOrders;
    //this.searchValues.completed = true;
    this.searchValues.ordernr = this.sOrder;
    this.searchValues.klantnaam = this.sKlant;
    this.indLoading = true;
    this._webapiService.post('api/Poltronesofa/Search', this.searchValues)
      .subscribe(poltronesofaInfo => {
        this.searchInfo = poltronesofaInfo;
        this.indLoading = false;
      },
        error => {
          this.msg = <any>error; console.error(error);
          this.indLoading = false;
        });
  }
    ProcessReceivedInfo(poltronesofaInfo: any): any {
      this.searchInfo = poltronesofaInfo;
  }

  Sort(column: string) {
    if (this.sortColumn == column) {
      this.sortAsc = !this.sortAsc
    } else {
      this.sortColumn = column;
      this.sortAsc = true;
    }
  }

  BuildRetour(infoLine: PoltronesofaInfo)
  {
    this.retourInfo = new PoltronesofaRetourInfo('', '', infoLine);
    this.retourInfoNeeded = true;
  }

  BuildInvoice(infoLine: PoltronesofaInfo) {
    this.indLoading = true;
    this._webapiService.post('api/Poltronesofa/Payment', infoLine)
      .subscribe(poltronesofaInfo => {
        this.indLoading = false;
        infoLine.paymentInsertedStatus = 1;
      },
        error => {
          this.msg = <any>error; console.error(error);
          this.indLoading = false;
        });
  }

  BuildStorageInvoice(infoLine: PoltronesofaInfo) {
    this.storageInfo = new PoltronesofaStorageInfo(0, infoLine);
    this.storageInfoNeeded = true;
  }

  SendRetour() {
    this.retourInfoNeeded = false;
    this.indLoading = true;
    this._webapiService.post('api/Poltronesofa/Retour', this.retourInfo)
      .subscribe(poltronesofaInfo => {
        this.indLoading = false;
        let index = this.searchInfo.findIndex(p => p.documentNo == this.retourInfo.info.documentNo);
        this.searchInfo[index].retourInsertedStatus = 1;
      },
        error => {
          this.msg = <any>error; console.error(error);
          this.indLoading = false;
      });
  }

  SendStorage() {
    this.storageInfoNeeded = false;
    this.indLoading = true;
    this._webapiService.post('api/Poltronesofa/Storage', this.storageInfo)
      .subscribe(poltronesofaInfo => {
        this.indLoading = false;
        let index = this.searchInfo.findIndex(p => p.documentNo == this.retourInfo.info.documentNo);
        this.searchInfo[index].storageInsertedStatus = 1;
      },
        error => {
          this.msg = <any>error; console.error(error);
          this.indLoading = false;
        });
  }
}
