import { Component } from '@angular/core';

import { OrtecFileParameters, OrtecNavitransCustomer, OrtecFileLines, OrtecFileModel, OrtecGridInfo, CustomOrderAmount } from './ortec'
import { WebapiService } from '../service/webapi.service';

@Component({
  selector: 'app-ortec',
  templateUrl: './ortec.component.html',
  styleUrls: ['./ortec.component.css']
})
/** ortec component*/
export class OrtecComponent {
  /** ortec ctor */
  ortecFileParameter: OrtecFileParameters;
  ortecFile: OrtecFileLines | null;
  ortecFileTemp: OrtecFileLines;
  customers: OrtecNavitransCustomer[];
  customerOrderAmount: CustomOrderAmount[];
  tempOrtecLine: number;
  indLoading: boolean = false;
  dialogVisible: boolean = false;
  cbOrders: boolean = false;
  selectedCustomer: string;
  msg = '';
  sortColumn: string;
  sortAsc: boolean;
  selected: number;
  selectedDate: Date = new Date(Date.now());
  filter: string;

  constructor(
    private _webapiService: WebapiService) {
  }

  ChangeDate(date: HTMLInputElement) {
    this.selectedDate = date.valueAsDate;
    this.GetCustomers();
  }

  ChangeCbOrders() {
    this.GetCustomers();
  }

  private GetCustomers() {
    this.indLoading = true;
    if (this.cbOrders) {
      this._webapiService.get('api/ortec/OrderCustomers')
        .subscribe(customer => {
          this.customers = customer;
          this.indLoading = false;
        },
          error => {
            this.msg = <any>error; console.error(error);
            this.indLoading = false;
          });
    } else {
      if (this.selectedDate) {
        this._webapiService.get('api/ortec/Customers?planDate=' + this.selectedDate.toJSON())
          .subscribe(customer => {
            this.customers = customer;
            this.indLoading = false;
          },
            error => {
              this.msg = <any>error; console.error(error);
              this.indLoading = false;
              this.dialogVisible = true;
            });
      }
    }
  }

  GetOrtecData(date: HTMLInputElement) {
    this.selectedDate = date.valueAsDate;
    this.GetOrtecInfo();
  }

  private GetOrtecInfo() {
    this.sortColumn = '';
    this.indLoading = true;
    this.ortecFileParameter = new OrtecFileParameters(this.selectedDate ? this.selectedDate : new Date(), this.selectedCustomer, this.cbOrders);
    this._webapiService.post('api/ortec/info', this.ortecFileParameter)
      .subscribe(ortecFileLines => {
        this.ProcessReceivedInfo(ortecFileLines);
        this.indLoading = false;
      },
        error => {
          this.msg = <any>error; console.error(error);
          this.indLoading = false;
          this.dialogVisible = true;
        });
  }

  MakeFile() {
    this.indLoading = true;
    this._webapiService.post('api/ortec/file', this.ortecFile)
      .subscribe(ortecFileLines => {
        this.msg = ortecFileLines;
        this.indLoading = false;
        this.dialogVisible = true;
      },
        error => {
          this.msg = <any>error; console.error(error);
          this.indLoading = false;
          this.dialogVisible = true;
        });
  }

  ResetOrtecData() {
    this.ortecFile = null;
  }

  ChangeSelected(item: OrtecGridInfo) {
    item.selected = !item.selected;
    this.BuildCustomerOrderAmount();
  }

  Sort(header: string) {
    if (header == this.sortColumn) { this.sortAsc = !this.sortAsc; }
    else { this.sortAsc = true; this.sortColumn = header; }
    switch (header) {
      case "Navitrans":
        this.ortecFile.ortecLines.sort((a, b) => {
          return this.compare(a.ortecLine.navitransNumber, b.ortecLine.navitransNumber, this.sortAsc)
        })
        break;
      case "client":
        this.ortecFile.ortecLines.sort((a, b) => {
          return this.compare(a.ortecLine.clientNumber, b.ortecLine.clientNumber, this.sortAsc)
        })
        break;
      case "reference":
        this.ortecFile.ortecLines.sort((a, b) => {
          return this.compare(a.ortecLine.reference, b.ortecLine.reference, this.sortAsc)
        })
        break;
      case "clientname":
        this.ortecFile.ortecLines.sort((a, b) => {
          return this.compare(a.ortecLine.clientname, b.ortecLine.clientname, this.sortAsc)
        })
        break;
      case "street":
        this.ortecFile.ortecLines.sort((a, b) => {
          return this.compare(a.ortecLine.street + ' ' + a.ortecLine.houseNumber, b.ortecLine.street + ' ' + b.ortecLine.houseNumber, this.sortAsc)
        })
        break;
      case "postalcode":
        this.ortecFile.ortecLines.sort((a, b) => {
          return this.compare(a.ortecLine.country + ' ' + a.ortecLine.postalcode, b.ortecLine.country + ' ' + b.ortecLine.postalcode, this.sortAsc)
        })
        break;
      case "city":
        this.ortecFile.ortecLines.sort((a, b) => {
          return this.compare(a.ortecLine.city, b.ortecLine.city, this.sortAsc)
        })
        break;
      case "timewindow":
        this.ortecFile.ortecLines.sort((a, b) => {
          return this.compare(a.ortecLine.clienttimewindow1start + '-' + a.ortecLine.clienttimewindow1end, b.ortecLine.clienttimewindow1start + '-' + b.ortecLine.clienttimewindow1end, this.sortAsc)
        })
        break;
      default:
        break;
    }
  }

  SetFilter(customer: string) {
    if (this.filter == customer) {
      this.filter = '';
    } else {
      this.filter = customer;
    }
  }

  compare(a: number | String, b: number | String, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  ProcessReceivedInfo(info: OrtecFileLines) {
    if (this.ortecFile) {
      this.ortecFileTemp = info;
      if (this.ortecFile.date == this.ortecFileTemp.date) {
        //this.ortecFile.ortecLines = this.ortecFile.ortecLines.concat(this.ortecFileTemp.ortecLines);
        this.MergeOrtecInfo(this.ortecFileTemp);
      }
      else {
        this.ortecFile = info;
      }
    } else {
      this.ortecFile = info;
    }
    this.BuildCustomerOrderAmount();
  }

  MergeOrtecInfo(dataToAdd: OrtecFileLines) {
    for (let ortecLine of dataToAdd.ortecLines) {
      this.tempOrtecLine = this.ortecFile.ortecLines.findIndex(o => o.ortecLine.navitransNumber === ortecLine.ortecLine.navitransNumber);
      if (this.tempOrtecLine > -1) {
        ortecLine.selected = this.ortecFile.ortecLines[this.tempOrtecLine].selected;
        this.ortecFile.ortecLines.splice(this.tempOrtecLine, 1);
      }
    }
    if (this.ortecFile.ortecLines) {
      this.ortecFile.ortecLines = this.ortecFile.ortecLines.concat(dataToAdd.ortecLines);
    } else {
      this.ortecFile.ortecLines = dataToAdd.ortecLines;
    }
  }

  BuildCustomerOrderAmount() {
    if (this.ortecFile) {
      this.selected = this.ortecFile.ortecLines.filter(item => item.selected).length;
      var discCustomers = Array.from(new Set(this.ortecFile.ortecLines.map(item => item.ortecLine.customerName)));
      if (this.customerOrderAmount && this.customerOrderAmount.length > 0) {
        this.customerOrderAmount = this.customerOrderAmount.filter(item => item.amount < 0);
      }
      for (var i = 0; i < discCustomers.length; i++) {
        if (this.customerOrderAmount && this.customerOrderAmount.length > 0) {
          this.customerOrderAmount.push(new CustomOrderAmount(discCustomers[i].toString(), this.ortecFile.ortecLines.filter(item => item.ortecLine.customerName === discCustomers[i]).length, this.ortecFile.ortecLines.filter(item => item.selected && item.ortecLine.customerName === discCustomers[i]).length));
        } else {
          this.customerOrderAmount = [new CustomOrderAmount(discCustomers[i].toString(), this.ortecFile.ortecLines.filter(item => item.ortecLine.customerName === discCustomers[i]).length, this.ortecFile.ortecLines.filter(item => item.selected && item.ortecLine.customerName === discCustomers[i]).length)];
        }
      }
    }
  }
}
