import { Component, OnInit } from '@angular/core';
import { ColruytTripToSelect } from './colruyt';
import { WebapiService } from '../service/webapi.service';

@Component({
  selector: 'app-colruyt',
  templateUrl: './colruyt.component.html',
  styleUrls: ['./colruyt.component.css']
})
export class ColruytComponent implements OnInit {
  colruytTrips: ColruytTripToSelect[];
  newColruytTrips: ColruytTripToSelect[];
  msg: string;
  errorVisible: boolean = false;
  fileDate: string;
  newColruytTrip: string;
  tripBijrijder: boolean = false;
  indLoading: boolean = false;
  constructor(private _webapiService: WebapiService) { }

  ngOnInit() {
    this.GetColruytTrips();
    this.ClearNewTrips();
  }

  private GetColruytTrips() {
    if (!this.fileDate) {
      this.fileDate = "";
    }
    this._webapiService.get('api/Colruyt/CurrentTrips?date=' + this.fileDate)
      .subscribe(currentTrips => {
        this.colruytTrips = currentTrips;
      },
        error => {
          this.ErrorHandling(error);
        });
  }
  ErrorHandling(error: any) {
    this.msg = <any>error;
    console.error(error);
    this.errorVisible = true;
  }
  ChangeExportDate(date: HTMLInputElement) {
    this.fileDate = date.value;
    this.GetColruytTrips();
  }
  RemoveTrip(trip: ColruytTripToSelect) {
    this.newColruytTrips = this.newColruytTrips.filter(c => c != trip);
  }
  ClearNewTrips() {
    this.newColruytTrips = [];
  }
  AddNewTrip() {
    if (this.newColruytTrip && this.fileDate) {
      if (this.newColruytTrip.length > 0 && this.fileDate.length > 0) {
        if (!this.newColruytTrips) {
          this.ClearNewTrips();
        }
        if (this.newColruytTrips.findIndex(c => c.colruytTrip == this.newColruytTrip && c.fileDate == this.fileDate) < 0) {
          this.newColruytTrips.push(new ColruytTripToSelect(null, this.newColruytTrip, this.fileDate, null, this.tripBijrijder));
        }
      }
    }
  }
  SendNewTrips() {
    if (this.newColruytTrips) {
      if (this.newColruytTrips.length > 0) {
        this._webapiService.post('api/Colruyt/NewTrips', this.newColruytTrips)
          .subscribe(quantity => {
            this.ClearNewTrips();
            this.GetColruytTrips();
            this.indLoading = false;
          },
            error => {
              this.ErrorHandling(error);
              this.indLoading = false;
            });
      }
    }
  }
  ChangeTripBijrijder(trip: ColruytTripToSelect) {
    console.log(trip);
    if (trip) {
      trip.bijrijder = !trip.bijrijder;
      this._webapiService.post('api/Colruyt/TripChangeBijrijder', trip)
        .subscribe(quantity => {
          this.indLoading = false;
        },
          error => {
            this.ErrorHandling(error);
            this.indLoading = false;
          });
    }
  }
}
