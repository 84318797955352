import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { WebapiService } from './service/webapi.service';
import { OrtecComponent } from './ortec/ortec.component';
import { LoadingComponent } from './Loading/loading.component';
import { DialogComponent } from './dialog/dialog.component';
import { UrbantzComponent } from './urbantz/urbantz.component';
import { PlanningComponent } from './planning/planning.component';
import { PoltronesofaComponent } from './poltronesofa/poltronesofa.component';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { ErrorComponent } from './error/error.component';
import { ColruytComponent } from './colruyt/colruyt.component';
import { BufferInfoComponent } from './buffer-info/buffer-info.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    OrtecComponent,
    LoadingComponent,
    DialogComponent,
    UrbantzComponent,
    PlanningComponent,
    PoltronesofaComponent,
    ErrorComponent,
    ColruytComponent,
    BufferInfoComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      { path: 'ortec', component: OrtecComponent },
      { path: 'urbantz', component: UrbantzComponent },
      { path: 'poltronesofa', component: PoltronesofaComponent },
      { path: 'colruyt', component: ColruytComponent },
      { path: 'bufferInfo', component: BufferInfoComponent },
      { path: '**', redirectTo: 'home' }
    ])
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    WebapiService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
