export class ColruytTrip {
  constructor(
    public tripNummer: string,
    public date: string
  ) {
  }
}

export class ColruytTripToSelect {
  constructor(
    public iD: number,
    public colruytTrip: string,
    public fileDate: string,
    public createdOn: string,
    public bijrijder: boolean
) { }
}
