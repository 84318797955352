export class PoltronesofaSearch {
  constructor(
    public ordernr: string,
    public klantnaam: string,
    public completed: boolean
  ) { }
}

//export class PoltronesofaInfo {
//  constructor(
//    public ordernr: string,
//    public klantnaam: string,
//    public retour: string,
//    public payment: string
//  ) { }
//}

export class PoltronesofaInfo {
  constructor(
    public documentNo: String,
    public reference: String,
    public addresscode: String,
    public name: String,
    public customerNo: String,
    public invoiceNo: String,
    public storageInvoiceNo: String,
    public retourNo: String,
    public shipmentNo: number,
    public fileNo: String,
    public retourInsertedStatus: number,
    public paymentInsertedStatus: number,
    public storageInsertedStatus: number) { }
}

export class PoltronesofaRetourInfo {
  constructor(
    public artikelOmschrijving: string,
    public email: string,
    public info: PoltronesofaInfo
  ) { }
}
export class PoltronesofaStorageInfo {
  constructor(
    public quantity: number,
    public info: PoltronesofaInfo
  ) { }
}
