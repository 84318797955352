import { PlanningCustomer, PlanningFileModel, PlanningDepot } from '../planning/planning';
import { extend } from 'webdriver-js-extender';

export class UrbantzModel extends PlanningFileModel {
  constructor(
    public selected: Boolean,
    public navitransNumber: String,
    public orderNo: number,
    public documentNo: String,
    public shipmentNo: number,
    public movementNo: number,
    public actionLineNo: number,
    public reference: String,
    public date: Date,
    public customerName: String,
    public clientNumber: String,
    public clientname: String,
    public address: String,
    public postalcode: String,
    public city: String,
    public country: String,
    public clienttimewindow1start: String,
    public clienttimewindow1end: String,
    public clienttimewindow2start: String,
    public clienttimewindow2end: String,
    public helperneeded: number,
    public dEPOT1: String,
    public allReadyExported: boolean,
    public phone: string,
    public comment: string
  ) {
    super(selected,navitransNumber, reference, clientname, clientNumber, customerName, address, postalcode, city, country, clienttimewindow1start, clienttimewindow1end, clienttimewindow2start, clienttimewindow2end, dEPOT1, allReadyExported, phone, comment);
  }
}

export class UrbantzPlanningLines {
  constructor(
    public planDate: Date,
    public orders: boolean,
    public planningLines: UrbantzModel[]
  ) { }
}

export class UrbantzNavitransCustomer extends PlanningCustomer {
  constructor(
    public date: Date,
    public clientNumber: string,
    public name: string,
    public searchName: string
  ) {
    super(date, clientNumber, name, searchName);
  }
}

export class UrbantzGetInfoParameters {
  constructor(
    public date: Date,
    public customerCode: string,
    public orders: boolean,
    public inCludeExported: boolean,
    public depot: string,
    public cityDepot: boolean
  ) { }
}
  export class UrbantzExportRounds {
    constructor(
      public exportDate: Date,
      public hub: string,
      public roundId: string) { }
}

export class UrbantzDepot extends PlanningDepot {
  constructor(
    public depot: string,
    public code: string
  ) {
    super(depot, code)
  }
}

export class UrbantzExportDepot {
  constructor(
    public code: string,
    public id: string
  ) { }
}

