import { Component, OnInit } from '@angular/core';
import { WebapiService } from '../service/webapi.service';
import { UrbantzNavitransCustomer, UrbantzModel, UrbantzPlanningLines, UrbantzGetInfoParameters, UrbantzExportRounds, UrbantzDepot, UrbantzExportDepot } from './Urbantz';
import * as FileSaver from 'file-saver';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { nearer } from 'q';

@Component({
  selector: 'app-urbantz',
  templateUrl: './urbantz.component.html',
  styleUrls: ['./urbantz.component.css']
})
/** Urbantz component*/
export class UrbantzComponent implements OnInit {
  /** Urbantz ctor */
  selectedDate: Date = new Date(Date.now());
  cbOrders: boolean = false;
  cbExported: boolean = false;
  indLoading: boolean = false;
  cbVisibleExported: boolean = true;
  checkExportDate: boolean = false;
  checkCityDepot: boolean = false;
  customers: UrbantzNavitransCustomer[];
  planningLines: UrbantzPlanningLines = new UrbantzPlanningLines(new Date(Date.now()), false, null);
  tempPlanningLines: UrbantzPlanningLines;
  tempPlanningLine: number;
  depots: UrbantzDepot[];
  //customerOrderAmount: CustomOrderAmount[];
  msg: string;
  urbantzGetInfoParameters: UrbantzGetInfoParameters;
  customer: string;
  roundId: string;
  depot: string;
  //exportDate: Date = new Date(Date.now());
  exportRound: UrbantzExportRounds = new UrbantzExportRounds(new Date(Date.now()), null, null);
  errorVisible: boolean = false;
  exportDepots: UrbantzExportDepot[];
  exportDepot: UrbantzExportDepot;

  constructor(
    private _webapiService: WebapiService) {
  }

  ngOnInit() {
    this.GetDepots();
    this.GetExportDepots();
  }

  ChangeDate(date: Date) {
    this.selectedDate = date;
    this.GetCustomers();
  }

  ChangeExportDate(date: HTMLInputElement) {
    this.exportRound.exportDate = date.valueAsDate;
  }
  ChangeExportCheck() { this.checkExportDate = !this.checkExportDate }

  ChangeCbOrders() {
    this.cbOrders = !this.cbOrders;
    this.GetCustomers();
  }

  ChangeCbIncludeExported() {
    this.cbExported = !this.cbExported;
  }

  private GetDepots() {
    this._webapiService.get('api/urbantz/Depots')
      .subscribe(depots => {
        this.depots = depots;
      },
        error => {
          this.ErrorHandling(error);
        });
  }

  private GetExportDepots() {
    this._webapiService.get('api/urbantz/ExportDepots')
      .subscribe(depots => {
        this.exportDepots = depots;
      },
        error => {
          this.ErrorHandling(error);
        });
  }

  private GetCustomers() {
    this.indLoading = true;
    if (this.cbOrders) {
      this._webapiService.get('api/urbantz/OrderCustomers')
        .subscribe(customer => {
          this.customers = customer;
          this.indLoading = false;
        },
          error => {
            this.ErrorHandling(error);
            this.indLoading = false;
          });
    } else {
      if (this.selectedDate) {
        this._webapiService.get('api/urbantz/Customers?planDate=' + this.selectedDate.toJSON())
          .subscribe(customer => {
            this.customers = customer;
            this.indLoading = false;
          },
            error => {
              this.ErrorHandling(error);
              this.indLoading = false;
            });
      } else {
        this.indLoading = false;
      }
    }
  }

  ResetPlanningData() {
    this.planningLines.planningLines = null;
  }

  ProcessReceivedInfo(info: UrbantzPlanningLines) {
    console.log(info);
    if (this.planningLines && this.planningLines.planningLines && this.planningLines.planningLines.length > 0) {
      this.tempPlanningLines = info;
      if (this.planningLines.planDate == this.tempPlanningLines.planDate) {
        //this.ortecFile.ortecLines = this.ortecFile.ortecLines.concat(this.ortecFileTemp.ortecLines);
        this.MergePlanningInfo(this.tempPlanningLines);
      }
      else {
        this.planningLines = info;
      }
    } else {
      this.planningLines = info;
    }
    //this.BuildCustomerOrderAmount();
  }

  MergePlanningInfo(dataToAdd: UrbantzPlanningLines) {
    for (let planLine of dataToAdd.planningLines) {
      this.tempPlanningLine = this.planningLines.planningLines.findIndex(o => o.navitransNumber === planLine.navitransNumber);
      if (this.tempPlanningLine > -1) {
        planLine.selected = this.planningLines.planningLines[this.tempPlanningLine].selected;
        this.planningLines.planningLines.splice(this.tempPlanningLine, 1);
      }
    }
    if (this.planningLines.planningLines) {
      this.planningLines.planningLines = this.planningLines.planningLines.concat(dataToAdd.planningLines);
    } else {
      this.planningLines.planningLines = dataToAdd.planningLines;
    }
  }

  GetPlanningData(planDate: Date) {
    this.selectedDate = planDate;
    this.GetUrbantzInfo();
  }

  ChangeCityDepot() {
    this.checkCityDepot = !this.checkCityDepot;
  }

  private GetUrbantzInfo() {
    this.indLoading = true;
    this.urbantzGetInfoParameters = new UrbantzGetInfoParameters(this.selectedDate ? this.selectedDate : new Date(Date.now()), this.customer, this.cbOrders, this.cbExported, this.depot, this.checkCityDepot);
    this._webapiService.post('api/Urbantz/info', this.urbantzGetInfoParameters)
      .subscribe(ortecFileLines => {
        this.ProcessReceivedInfo(ortecFileLines);
        this.indLoading = false;
      },
        error => {
          this.ErrorHandling(error);
          this.indLoading = false;
        });
  }

  ChangeSelectedCustomer(customer: string) {
    console.log(customer);
    this.customer = customer;
  }

  ChangeSelectedDepot(depot: string) {
    this.depot = depot;
  }

  ChangeSelected(item: String) {
    this.planningLines.planningLines.filter(p => p.navitransNumber === item).forEach(f => f.selected = !f.selected);
  }

  MakeFile() {
    this.indLoading = true;
    this._webapiService.postFile('api/Urbantz/file', this.planningLines)
      .subscribe(ortecFileLines => {
        console.log('1');
        console.log(ortecFileLines);
        console.log(ortecFileLines.headers);
        console.log(this.parseFilenameFromContentDisposition(ortecFileLines.headers.get('Content-Disposition')));
        FileSaver.saveAs(ortecFileLines.body, this.parseFilenameFromContentDisposition(ortecFileLines.headers.get('Content-Disposition')));
        //const url = window.URL.createObjectURL(ortecFileLines);
        //window.open(url);
        //var downloadUrl = URL.createObjectURL(ortecFileLines);
        //window.open(downloadUrl);
        this.indLoading = false;
      },
        error => {
          this.ErrorHandling(error);
          this.indLoading = false;
        });
  }

  private parseFilenameFromContentDisposition(contentDisposition) {
    if (!contentDisposition) return null;
    let matches = /filename="(.*?)"/g.exec(contentDisposition);

    if (matches && matches.length > 1) {
      return matches[1];
    } else {
      matches = /filename=(.*?);/g.exec(contentDisposition); if (matches && matches.length > 1) {
        return matches[1];
      } else { return null; }
    }
  }

  ExportUbrantzRound() {
    this.indLoading = true;
    console.log(this.exportRound);
    this._webapiService.post('api/Urbantz/urbantzRoundExport', this.exportRound)
      .subscribe(ortecFileLines => {
        this.indLoading = false;
      },
        error => {
          this.ErrorHandling(error);
          this.indLoading = false;
      });
    this.checkExportDate = false;
  }

  ChangeExportDepot() {
  }

  ErrorHandling(error: any) {
    this.msg = <any>error;
    console.error(error);
    this.errorVisible = true;
  }
}
